import axios from 'axios'
import type { Abi, Address } from 'viem'
import { env } from 'env.client'

export const getL1Erc20TokenAbi = async ({ l1TokenAddress }: { l1TokenAddress: Address }) => {
  try {
    const { data } = await axios.get<string>('/api/l1Erc20TokenAbi', {
      params: { contractAddress: l1TokenAddress },
    })

    const abi = JSON.parse(data)

    return abi
  } catch (error) {
    console.log('Failed to fetch token L1 ABI', error)
    return null
  }
}

export const getL2Erc20TokenAbi = async ({ l2TokenAddress }: { l2TokenAddress: Address }) => {
  try {
    const {
      data: { abi },
    } = await axios.get<{ abi: Abi }>(`${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/api/l2Erc20TokenAbi`, {
      params: { contractAddress: l2TokenAddress },
    })

    return abi
  } catch (error) {
    console.log('Failed to fetch token L2 ABI', error)
    return null
  }
}
