import { Big } from 'big.js'
import type { TFunction } from 'next-i18next'

export const getSelectedExternalBridge = (
  bridge: string,
  amount: string,
  t: TFunction,
): {
  url: string
  bridgeMessage: string
} => {
  if (bridge === 'amphor') {
    return {
      url: 'https://amphor.hl.superbridge.app/',
      bridgeMessage: t('externalBridge.AmphorBridge'),
    }
  }

  if (bridge === 'stakestone') {
    return {
      url: 'https://app.stakestone.io/u/eth/bridge',
      bridgeMessage: t('externalBridge.StakestoneBridge'),
    }
  }

  if (bridge === 'stargate') {
    return {
      url: 'https://stargate.finance/bridge',
      bridgeMessage: t('externalBridge.StargateBridge'),
    }
  }

  if (bridge === 'free' && Big(amount || '0').gt(0.5)) {
    return {
      url: 'https://tunnel.free.tech/',
      bridgeMessage: t('externalBridge.FreeBridge'),
    }
  }

  if (bridge === 'free' && Big(amount || '0').lte(0.5)) {
    return {
      url: 'https://app.free.tech/',
      bridgeMessage: t('externalBridge.FreeBridge'),
    }
  }

  if (bridge === 're7-hyperlane') {
    return {
      url: 'https://re7lrt.hl.superbridge.app/',
      bridgeMessage: t('externalBridge.HyperlaneBridge'),
    }
  }

  if (bridge === 'eigenpie') {
    return {
      url: 'https://eigenlayer.magpiexyz.io/bridge',
      bridgeMessage: t('externalBridge.EigenpieBridge'),
    }
  }

  if (bridge === 'avalon') {
    return {
      url: 'https://usda.avalonfinance.xyz/bridge/',
      bridgeMessage: t('externalBridge.AvalonBridge'),
    }
  }

  if (bridge === 'steak-lrt') {
    return {
      url: 'https://steaklrt.hl.superbridge.app',
      bridgeMessage: t('externalBridge.SteakLrtBridge'),
    }
  }

  return {
    url: 'https://renzo.hyperlane.xyz/',
    bridgeMessage: t('externalBridge.HyperlaneBridge'),
  }
}
