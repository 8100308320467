import { Flex, FormControl, type FormControlProps, FormLabel, Text } from '@chakra-ui/react'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'

type FormFieldWrapperProps = {
  children: React.ReactNode
  label: string
  rightContent?: React.ReactNode
} & FormControlProps

export const FormFieldWrapper = ({
  children,
  label,
  rightContent,
  ...props
}: FormFieldWrapperProps) => {
  const COLORS = useThemeColors()

  return (
    <FormControl {...props}>
      <Flex
        alignItems={{ base: 'start', md: 'center' }}
        justifyContent="space-between"
        flexDir={{ base: 'column', md: 'row' }}
        mb={{ base: 1, md: 0 }}
      >
        <Text as={FormLabel} mb={1.5} variant="text3regular" color={COLORS.grey03}>
          {label}
        </Text>
        {rightContent}
      </Flex>
      {children}
    </FormControl>
  )
}
