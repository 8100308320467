import { Box, Flex, IconButton, Image, Tooltip } from '@chakra-ui/react'
import { watchAsset } from '@wagmi/core'
import React from 'react'
import { useIsUpToDesktopWidth } from 'components/Breakpoint'
import { zircuit } from 'constants/network'
import type { Erc20TokenAsset } from 'hooks/useAssets'
import { useThemeColors } from 'hooks/useThemeColors'
import { useToast } from 'hooks/useToast'
import { wagmiConfig } from 'lib/wagmi'
import { getWatchAssetSymbol } from 'utils/common'
import { getImageSrc } from 'utils/getImageSrc'

type AddAssetButtonProps = {
  chainId: number
  onIncorrectNetwork: () => void
} & Erc20TokenAsset

export const AddAssetButton = ({
  contract_address_l1,
  contract_address_l2,
  symbol,
  decimals_l2,
  chainId,
  onIncorrectNetwork,
  ...rest
}: AddAssetButtonProps) => {
  const COLORS = useThemeColors()
  const toast = useToast()
  const targetRef = React.useRef<HTMLDivElement | null>(null)
  const { isUpToDesktopWidth } = useIsUpToDesktopWidth()

  return (
    <Flex>
      {/* Fixes tooltip rendering in modals */}
      <Box ref={targetRef} />
      <Tooltip
        shouldWrapChildren
        label="Add asset to wallet"
        portalProps={{ containerRef: targetRef, appendToParentPortal: true }}
        isDisabled={isUpToDesktopWidth}
      >
        <IconButton
          variant="unstyled"
          size="xl"
          aria-label="Add asset to wallet"
          icon={
            <Box w={6} h={6}>
              <Image src="/assets/meta_mask.svg" alt="Add asset to wallet" w={6} h={6} />
            </Box>
          }
          p={1}
          borderRadius={4}
          borderWidth={1}
          borderColor={COLORS.zircuitPrimary}
          _hover={{
            cursor: 'pointer',
            opacity: 0.5,
          }}
          onClick={async () => {
            if (chainId !== zircuit.id) {
              onIncorrectNetwork()
              return
            }

            try {
              await watchAsset(wagmiConfig, {
                type: 'ERC20',
                options: {
                  address: contract_address_l2,
                  symbol: getWatchAssetSymbol(symbol),
                  decimals: decimals_l2,
                  image: getImageSrc({
                    contract_address_l1,
                    symbol,
                    bridge: 'bridge' in rest,
                  }).replace('.svg', '.png'),
                },
              })
            } catch (error) {
              if (!(error instanceof Error)) {
                return
              }

              // Check for WalletConnect specific error
              if (error.message.includes('wallet_watchAsset')) {
                toast({
                  status: 'danger',
                  message:
                    'Your wallet does not support adding custom tokens. Please add it manually through your wallet interface.',
                })
                return
              }

              toast({
                status: 'danger',
                message:
                  'shortMessage' in error && typeof error.shortMessage === 'string'
                    ? error.shortMessage
                    : error.message,
              })
            }
          }}
        />
      </Tooltip>
    </Flex>
  )
}
