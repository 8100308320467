import React from 'react'

const DEBOUNCE_TIME = 500

export const useDebouncedValue = (value: string, debounceTime = DEBOUNCE_TIME) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, debounceTime)

    return () => {
      clearTimeout(handler)
    }
  }, [value, debounceTime])

  return debouncedValue
}
