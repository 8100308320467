import { Link } from '@chakra-ui/next-js'
import { Text, Flex, Input, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { Trans, useTranslation } from 'next-i18next'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Alert } from 'components/Alert'
import type { AddressFormInputsType } from 'components/AssetPicker/AssetPicker'
import { env } from 'env.client'
import { useThemeColors } from 'hooks/useThemeColors'

export const AddManualToken = () => {
  const { t } = useTranslation(['common'])
  const COLORS = useThemeColors()
  const {
    register,
    formState: { errors },
  } = useFormContext<AddressFormInputsType>()

  return (
    <form>
      <Flex gap={4} flexDir="column">
        <FormControl isInvalid={!!errors.l1TokenAddress}>
          <Text variant="caption1regular" mb={1.5} color={COLORS.grey02}>
            {t('common:addTokenModal.L1Address')}
          </Text>
          <Input placeholder="0x.." {...register('l1TokenAddress')} />
          <FormErrorMessage>
            {errors.l1TokenAddress && errors.l1TokenAddress.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.l2TokenAddress}>
          <Text variant="caption1regular" mb={1.5} color={COLORS.grey02}>
            {t('common:addTokenModal.L2Address')}
          </Text>
          <Input placeholder="0x.." {...register('l2TokenAddress')} />
          <FormErrorMessage>
            {errors.l2TokenAddress && errors.l2TokenAddress.message}
          </FormErrorMessage>
        </FormControl>
        <Alert variant="warning">
          <Trans
            t={t}
            i18nKey="common:AssetPicker.Erc20TokenCriteria"
            components={{
              Link: (
                <Link
                  href={`${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/address/0x4200000000000000000000000000000000000012?activeTab=3`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="tertiary"
                />
              ),
            }}
            values={{
              percentage: '90%',
            }}
          />
        </Alert>
      </Flex>
    </form>
  )
}
